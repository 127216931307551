import React, { useContext, useEffect } from 'react'

import { GetStaticProps } from 'next'

import PageDataService from '@lib/pageData/pageDataService'
import Layout from '../modules/layout/Layout'
import NotFoundNotificatipn from '../components/shared/NotFoundNotification'
import ProductOverview from '../components/shared/ProductOverview'
import ShopContext from '../context/ShopContext'
import SocialMedia from '../components/shared/SocialMedia'
import { IProductsBySlug } from '../modules/products/interfaces/productInterfaces'
import { IMenuItem } from '../modules/layout/header/navigation/menu.model'

import { bedtimeGreensSlug, powerGreensSlug, powerKombiGreensSlug } from '../utils/slugs'

interface IHome {
  productsBySlug: IProductsBySlug
  topMenu: IMenuItem[]
}

const Home: React.FC<IHome> = ({ productsBySlug, topMenu }) => {
  const { setProductsBySlug } = useContext(ShopContext)

  useEffect(() => {
    setProductsBySlug(productsBySlug)
  }, [productsBySlug])

  return (
    <Layout
      description="yoself"
      title="Diese Seite gibt es (noch) nicht"
      indexable={false}
      topMenu={topMenu}>
      <NotFoundNotificatipn />
      <SocialMedia />
      <ProductOverview productsBySlug={productsBySlug} />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const pageDataService = new PageDataService(context)
  const products = await pageDataService.getProductsBySlug([
    bedtimeGreensSlug,
    powerGreensSlug,
    powerKombiGreensSlug
  ])

  return {
    props: products,
    ...pageDataService.getSettings()
  }
}
export default Home
